import { createReducer } from "@reduxjs/toolkit";
import {
  COLOR_PALETTE_ERROR,
  COLOR_PALETTE_LIST,
  COLOR_PALETTE_LOADING,
  CREATE_COLOR_PALETTE,
  DELETE_COLOR_PALETTE,
  EDIT_COLOR_PALETTE,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const colorPaletteReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(COLOR_PALETTE_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(COLOR_PALETTE_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(COLOR_PALETTE_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(CREATE_COLOR_PALETTE, (state, { payload }) => {
      Array.isArray(state.data)
        ? state.data.push(payload)
        : (state.data = [payload]);
      state.loading = false;
    })
    .addCase(EDIT_COLOR_PALETTE, (state, { payload }) => {
      const index = state.data.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
    })
    .addCase(DELETE_COLOR_PALETTE, (state, { payload }) => {
      state.data = state.data.filter((e) => e?.id !== payload?.id);
      state.loading = false;
    });
});

export default colorPaletteReducer;
