import { createReducer } from "@reduxjs/toolkit";
import {
  CREATE_PAYMENT,
  EDIT_PAYMENT,
  LIST_PAYMENT,
  PAYMENT_ERROR,
  PAYMENT_LOADING,
  STATUS_PAYMENT,
  STATUS_PAYMENT_ERROR,
  STATUS_PAYMENT_LOADING,
} from "../actions/types";

const initialStatus = {
  error: "",
  loading: false,
  data: [],
};

const initialState = {
  error: "",
  loading: false,
  data: [],
  status: initialStatus,
};

const PaymentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PAYMENT_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(PAYMENT_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(LIST_PAYMENT, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    })
    .addCase(CREATE_PAYMENT, (state, { payload }) => {
      Array.isArray(state.data)
        ? state.data.push(payload)
        : (state.data = [payload]);
      state.loading = false;
    })
    .addCase(EDIT_PAYMENT, (state, { payload }) => {
      const index = state.data.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
    })
    .addCase(STATUS_PAYMENT_LOADING, (state) => {
      state.status.loading = true;
    })
    .addCase(STATUS_PAYMENT_ERROR, (state, { payload }) => {
      state.status.error = payload;
      state.status.loading = false;
    })
    .addCase(STATUS_PAYMENT, (state, { payload }) => {
      state.status.data = payload;
      state.status.loading = false;
    });
});
export default PaymentReducer;
