import { createReducer } from "@reduxjs/toolkit";
import {
  MODULE_LOADING,
  GET_MODULE,
  MODULE_ERROR,
  COPY_MODULE,
  GET_DYNAMIC_MODULES,
  CREATE_MODULE,
  GET_SINGLE_MODULE,
  Delete_MODULE,
  EDIT_MODULE,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
  dynamicModules: [],
  singleModule: {},
};

const moduleReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(MODULE_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(MODULE_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_MODULE, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(GET_DYNAMIC_MODULES, (state, { payload }) => {
      state.dynamicModules = payload;
      state.loading = false;
    })
    .addCase(COPY_MODULE, (state, { payload }) => {
      state.data.push(payload);
      state.loading = false;
    })
    .addCase(CREATE_MODULE, (state, { payload }) => {
      state.data.push(payload);
      state.loading = false;
    })
    .addCase(GET_SINGLE_MODULE, (state, { payload }) => {
      state.singleModule = payload;
    })
    .addCase(Delete_MODULE, (state, { payload }) => {
      const newState = state?.data?.filter((e) => e.id !== payload?.moduleId);
      state.data = newState;
    })
    .addCase(EDIT_MODULE, (state, { payload }) => {
      const index = state.data.findIndex((e) => payload?.id === e?.id);
      state.data[index] = payload;
      state.loading = false;
    });
});

export default moduleReducer;
