import { createReducer } from "@reduxjs/toolkit";
import {
  CREATE_INDUSTRIES,
  DELETE_INDUSTRIES,
  EDIT_INDUSTRIES,
  INDUSTRIES_ERROR,
  INDUSTRIES_LIST,
  INDUSTRIES_LOADING,
  SUB_INDUSTRIES_ERROR,
  SUB_INDUSTRIES_LIST,
  SUB_INDUSTRIES_LOADING,
} from "../actions/types";

const subState = {
  error: "",
  loading: false,
  data: [],
};

const initialState = {
  error: "",
  loading: false,
  data: [],
  sub: subState,
};

const industriesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(INDUSTRIES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(INDUSTRIES_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(INDUSTRIES_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(CREATE_INDUSTRIES, (state, { payload }) => {
      Array.isArray(state.data)
        ? state.data.push(payload)
        : (state.data = [payload]);
      state.loading = false;
    })
    .addCase(EDIT_INDUSTRIES, (state, { payload }) => {
      const index = state.data.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
    })
    .addCase(DELETE_INDUSTRIES, (state, { payload }) => {
      state.data = state.data.filter((e) => e?.id !== payload?.id);
      state.loading = false;
    })
    .addCase(SUB_INDUSTRIES_LOADING, (state) => {
      state.sub.loading = true;
    })
    .addCase(SUB_INDUSTRIES_ERROR, (state, { payload }) => {
      state.sub.error = payload;
      state.sub.loading = false;
    })
    .addCase(SUB_INDUSTRIES_LIST, (state, { payload }) => {
      state.sub.data = payload;
      state.sub.loading = false;
    });
});

export default industriesReducer;
