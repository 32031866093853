import { createReducer } from "@reduxjs/toolkit";
import {
  FILTER_LOADING,
  FILTER_ERROR,
  ADD_FILTER,
  GET_FILTERS,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const filterReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(FILTER_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(FILTER_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_FILTERS, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(ADD_FILTER, (state, { payload }) => {
      state.data.push(payload);
      state.loading = false;
    });
});

export default filterReducer;
