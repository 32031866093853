import { createReducer } from "@reduxjs/toolkit";
import {
  APPS_ERROR,
  APPS_LOADING,
  APPS_LIST,
  APP_SHOW,
  EDIT_APP,
  DELETE_APP,
  COPY_APP,
  APP_LOADING,
  DELETED_APPS_LIST,
  DELETED_APPS_DELETE,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  thisPage: 0,
  allPages: 0,
  data: [],
  deletedApps: [],
  singleApp: {},
  loadingApp: false,
};

const appsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(APPS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(APP_LOADING, (state) => {
      state.loadingApp = true;
    })
    .addCase(APPS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(APPS_LIST, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload.data
          : [...state.data, ...payload.data];
      state.allPages = payload.allPages;
      state.thisPage = payload.thisPage;
      state.loading = false;
    })
    .addCase(DELETED_APPS_LIST, (state, { payload }) => {
      state.deletedApps =
        payload?.thisPage === 1
          ? payload.data
          : [...state.deletedApps, ...payload.data];
      state.allPages = payload.allPages;
      state.thisPage = payload.thisPage;
      state.loading = false;
    })
    .addCase(DELETED_APPS_DELETE, (state, { payload }) => {
      state.deletedApps = state?.deletedApps?.filter(
        (e) => e?.id !== payload?.id
      );
      state.loading = false;
    })
    
    .addCase(APP_SHOW, (state, { payload }) => {
      state.singleApp = payload[0];
      state.loadingApp = false;
    })
    .addCase(EDIT_APP, (state, { payload }) => {
      state.loading = false;
    })
    .addCase(DELETE_APP, (state, { payload }) => {
      state.data = state?.data?.filter((e) => e?.id !== payload?.id);
      state.loading = false;
    })
    .addCase(COPY_APP, (state, { payload }) => {
      state.data.unshift(payload);
    });
});

export default appsReducer;
