import { createReducer } from "@reduxjs/toolkit";
import {
  PACKAGES_LOADING,
  PACKAGES_ERROR,
  PACKAGES_LIST,
  SYSTEM_PACKAGES_LOADING,
  SYSTEM_PACKAGES_ERROR,
  SYSTEM_PACKAGES_LIST,
  SYSTEM_PACKAGES_EDIT,
  SYSTEM_PACKAGES_DELETE
} from "../actions/types";

const systemState = {
  error: "",
  loading: false,
  data: [],
  count: 0,
  allPages: 0,
  thisPage: 0,
};

const initialState = {
  error: "",
  loading: false,
  data: [],
  system: systemState,
};

const packagesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PACKAGES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(PACKAGES_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(PACKAGES_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(SYSTEM_PACKAGES_LOADING, (state) => {
      state.system.loading = true;
    })
    .addCase(SYSTEM_PACKAGES_ERROR, (state, { payload }) => {
      state.system.error = payload;
      state.system.loading = false;
    })
    .addCase(SYSTEM_PACKAGES_LIST, (state, { payload }) => {
      state.system.loading = false;
      state.system.data =
        payload?.thisPage === 1
          ? payload.data
          : [...state.system.data, ...payload.data];
      state.system.allPages = payload.allPages;
      state.system.thisPage = payload.thisPage;
    })
    .addCase(SYSTEM_PACKAGES_DELETE, (state, { payload }) => {
      state.system.data = state.system.data.filter(
        (e) => e?.id !== payload?.id
      );
      state.system.loading = false;
    })
    .addCase(SYSTEM_PACKAGES_EDIT, (state, { payload }) => {
      const index = state.system.data.findIndex((e) => e?.id === payload?.id);
      state.system.data[index] = payload;
      state.system.loading = false;
    });
});

export default packagesReducer;
