import { createReducer } from "@reduxjs/toolkit";
import { TAGS_LOADING, TAGS_ERROR, GET_TAGS } from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const tagsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(TAGS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(TAGS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_TAGS, (state, { payload }) => {
      state.data = payload?.filter((e) => e?.name);
      state.loading = false;
    });
});

export default tagsReducer;
