import { createReducer } from "@reduxjs/toolkit";
import {
  CREATE_WEBSLIDER,
  WEBSLIDER_ERROR,
  WEBSLIDER_LOADING,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const webSliderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(WEBSLIDER_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(WEBSLIDER_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(CREATE_WEBSLIDER, (state, { payload }) => {
      Array.isArray(state.data)
        ? state.data.push(payload)
        : (state.data = [payload]);
      state.loading = false;
    });
  // .addCase(COMPONENT_CATEGORY_LIST, (state, { payload }) => {
  //   state.data = payload;
  //   state.loading = false;
  // })
  // .addCase(EDIT_COMPONENT_CATEGORY, (state, { payload }) => {
  //   const index = state.data.findIndex((e) => e?.id === payload?.id);
  //   state.data[index] = payload;
  //   state.loading = false;
  // })
  // .addCase(DELETE_COMPONENT_CATEGORY, (state, { payload }) => {
  //   state.data = state.data.filter((e) => e?.id !== payload?.id);
  //   state.loading = false;
  // })
});

export default webSliderReducer;
