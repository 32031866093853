import { createReducer } from "@reduxjs/toolkit";
import {
  MODULETYPE_LOADING,
  GET_MODULETYPE,
  MODULETYPE_ERROR,
  CREATE_MODULETYPE,
  EDIT_MODULETYPE,
  DELETE_MODULETYPE,
  LIST_MODULETYPE,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
  list: [],
};

const moduleTypeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(MODULETYPE_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(MODULETYPE_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_MODULETYPE, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(LIST_MODULETYPE, (state, { payload }) => {
      state.list = payload;
      state.loading = false;
    })
    .addCase(CREATE_MODULETYPE, (state, { payload }) => {
      Array.isArray(state.list)
        ? state.list.push(payload)
        : (state.list = [payload]);
      state.loading = false;
    })
    .addCase(EDIT_MODULETYPE, (state, { payload }) => {
      const index = state.list.findIndex((e) => e?.id === payload?.id);
      state.list[index] = payload;
      state.loading = false;
    })
    .addCase(DELETE_MODULETYPE, (state, { payload }) => {
      state.list = state.list.filter((e) => e?.id !== payload?.id);
      state.loading = false;
    });
});

export default moduleTypeReducer;
