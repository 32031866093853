import { createReducer } from "@reduxjs/toolkit";
import {
  TABS_AND_fORMS_LOADING,
  GET_TABS_AND_fORMS,
  TABS_AND_fORMS_ERROR,
  CHANGE_TAB,
  CREATE_TAB,
  DELETE_TAB,
  EDIT_TAB,
  CREATE_FORM,
  DELETE_FORM,
  COPY_FORM,
  GET_SINGLE_FORM,
  EDIT_FORM,
  GET_fORMS,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  tabs: [],
  forms: [],
  currentTab: {},
  singleForm: {},
};

const tabsAndFormsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(TABS_AND_fORMS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(TABS_AND_fORMS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_TABS_AND_fORMS, (state, { payload }) => {
      state.tabs = payload.tabs;
      state.currentTab = payload?.tabs?.length ? payload.tabs[0] : {};
      state.forms = payload.forms;
      state.loading = false;
    })
    .addCase(GET_fORMS, (state, { payload }) => {
      state.forms = payload;
      state.loading = false;
    })
    .addCase(CHANGE_TAB, (state, { payload }) => {
      state.currentTab = payload;
    })
    .addCase(CREATE_TAB, (state, { payload }) => {
      state.tabs.push(payload);
    })

    .addCase(DELETE_TAB, (state, { payload }) => {
      state.tabs = state.tabs.filter((e) => e?.id !== payload);
    })
    .addCase(DELETE_FORM, (state, { payload }) => {
      state.forms = state.forms.filter((e) => e?.id !== payload);
    })
    .addCase(COPY_FORM, (state, { payload }) => {
      state.forms.push(payload);
    })
    .addCase(EDIT_TAB, (state, { payload }) => {
      const index = state.tabs.findIndex((e) => payload?.id === e?.id);
      state.tabs[index] = payload;
    })
    .addCase(CREATE_FORM, (state, { payload }) => {
      state.forms.push(payload);
    })
    .addCase(GET_SINGLE_FORM, (state, { payload }) => {
      state.singleForm = payload;
    })
    .addCase(EDIT_FORM, (state, { payload }) => {
      const index = state.forms.findIndex((e) => payload?.id === e?.id);
      state.forms[index] = payload;
    });
});

export default tabsAndFormsReducer;
