import { createReducer } from "@reduxjs/toolkit";
import { ACTION_LOADING, GET_ACTION, ACTION_ERROR } from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const actionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ACTION_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(ACTION_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_ACTION, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default actionReducer;
