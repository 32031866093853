import { createReducer } from "@reduxjs/toolkit";
import {
  GET_APP_TYPES,
  APP_TYPES_ERROR,
  APP_TYPES_LOADING,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const appTypeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(APP_TYPES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(APP_TYPES_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_APP_TYPES, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default appTypeReducer;
