import { createReducer } from "@reduxjs/toolkit";
import {
  APP_TYPE_LOADING,
  GET_APP_TYPE,
  APP_TYPE_ERROR,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const appTypesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(APP_TYPE_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(APP_TYPE_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_APP_TYPE, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default appTypesReducer;
