import { createReducer } from "@reduxjs/toolkit";
import {
    SCREENS_LOADING, GET_SCREENS, SCREENS_ERROR
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: {},
};

const screenReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(SCREENS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(SCREENS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_SCREENS, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default screenReducer;
