import { createReducer } from "@reduxjs/toolkit";
import { ITEMS_LOADING, GET_ITEMS, ITEMS_ERROR } from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const itemsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ITEMS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(ITEMS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_ITEMS, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default itemsReducer;
