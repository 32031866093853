import { createReducer } from "@reduxjs/toolkit";
import {
  EDIT_FILE_FIELDS_AR,
  FILE_FIELDS_AR_ERROR,
  FILE_FIELDS_AR_LIST,
  FILE_FIELDS_AR_LOADING,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
  allPages: 0,
  thisPage: 0,
};

const fileFieldsArReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(FILE_FIELDS_AR_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(FILE_FIELDS_AR_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(FILE_FIELDS_AR_LIST, (state, { payload }) => {
      state.loading = false;

      state.data =
        payload?.thisPage === 1
          ? payload.data
          : [...state.data, ...payload.data];
      state.allPages = payload.allPages;
      state.thisPage = payload.thisPage;
    })

    .addCase(EDIT_FILE_FIELDS_AR, (state, { payload }) => {
      const index = state.data.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
    });
});

export default fileFieldsArReducer;
