import { createReducer } from "@reduxjs/toolkit";
import {
  ACTION_ALL_LOADING,
  GET_ACTION_ALL,
  ACTION_ALL_ERROR,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const allActionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(ACTION_ALL_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(ACTION_ALL_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_ACTION_ALL, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default allActionReducer;
