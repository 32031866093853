import { createReducer } from "@reduxjs/toolkit";
import {
  LANGUAGES_LOADING,
  GET_LANGUAGES,
  LANGUAGES_ERROR,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const languagesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LANGUAGES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(LANGUAGES_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_LANGUAGES, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default languagesReducer;
