import { createReducer } from "@reduxjs/toolkit";
import {
  CREATE_DEFAULT,
  DEFAULT_ERROR,
  DEFAULT_LOADING,
  DELETE_DEFAULT,
  EDIT_DEFAULT,
  FOOTER_ERROR,
  FOOTER_LOADING,
  GET_DEFAULT,
  GET_FOOTER,
  GET_HEADER,
  HEADER_ERROR,
  HEADER_LOADING,
  LIST_DEFAULT,
} from "../actions/types";
const headerState = {
  error: "",
  loading: false,
  data: [],
};
const footerState = {
  error: "",
  loading: false,
  data: [],
};
const initialState = {
  error: "",
  loading: false,
  data: [],
  singleDefault: {},
  header: headerState,
  footer: footerState,
};

const defaultReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(HEADER_LOADING, (state) => {
      state.header.loading = true;
    })
    .addCase(HEADER_ERROR, (state, { payload }) => {
      state.header.error = payload;
      state.header.loading = false;
    })
    .addCase(GET_HEADER, (state, { payload }) => {
      state.header.data = payload;
      state.header.loading = false;
    })
    .addCase(FOOTER_LOADING, (state) => {
      state.footer.loading = true;
    })
    .addCase(FOOTER_ERROR, (state, { payload }) => {
      state.footer.error = payload;
      state.footer.loading = false;
    })
    .addCase(GET_FOOTER, (state, { payload }) => {
      state.footer.data = payload;
      state.footer.loading = false;
    })
    .addCase(DEFAULT_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(DEFAULT_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_DEFAULT, (state, { payload }) => {
      state.singleDefault = payload;
      state.loading = false;
    })
    .addCase(LIST_DEFAULT, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(CREATE_DEFAULT, (state, { payload }) => {
      Array.isArray(state.data)
        ? state.data.push(payload)
        : (state.data = [payload]);
      state.loading = false;
    })
    .addCase(EDIT_DEFAULT, (state, { payload }) => {
      const index = state.data.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
    })
    .addCase(DELETE_DEFAULT, (state, { payload }) => {
      state.data = state.data.filter((e) => e?.id !== payload?.id);
      state.loading = false;
    });
});

export default defaultReducer;
