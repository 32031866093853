import { createReducer } from "@reduxjs/toolkit";
import {
  STYLES_LOADING,
  GET_STYLES,
  STYLES_ERROR,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const stylesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(STYLES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(STYLES_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_STYLES, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default stylesReducer;
