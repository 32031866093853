import { createReducer } from "@reduxjs/toolkit";
import {
  CATEGORIES_LOADING,
  GET_CATEGORIES,
  CATEGORIES_ERROR,
  CREATE_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const categoriesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CATEGORIES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(CATEGORIES_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_CATEGORIES, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(CREATE_CATEGORY, (state, { payload }) => {
      Array.isArray(state.data)
        ? state.data.push(payload)
        : (state.data = [payload]);
      state.loading = false;
    })
    .addCase(EDIT_CATEGORY, (state, { payload }) => {
      const index = state.data.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
    })
    .addCase(DELETE_CATEGORY, (state, { payload }) => {
      state.data = state.data.filter((e) => e?.id !== payload?.id);
      state.loading = false;
    });
});
//http://localhost:3000/modules/3114/categories/205
export default categoriesReducer;
