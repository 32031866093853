import { Navigate, Route, Routes } from "react-router-dom";
import { lazy } from "react";
import { useSelector } from "react-redux";

import SuspenseComponent from "../Components/SuspenseComponent";
import { AppLoader } from "../splash";

const Main = lazy(() => import("../pages/Main"));
const Login = lazy(() => import("../pages/Login"));
const Forgot = lazy(() => import("../pages/ForgotPassword"));
const Reset = lazy(() => import("../pages/ResetPassword"));
const Verify = lazy(() => import("../pages/VerifyEmail"));


export const Router = ({ mode, changeMode }) => {
  const { isLoading: isAuthLoading } = useSelector((s) => s?.auth);

  if (isAuthLoading) return <AppLoader />;

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <SuspenseComponent>
            <Main />
          </SuspenseComponent>
        }
      />
         <Route
        path="reset"
        element={
          <SuspenseComponent>
            <Reset />
          </SuspenseComponent>
        }
      />
      <Route
        path="login"
        element={
          <SuspenseComponent>
            <Login />
          </SuspenseComponent>
        }
      />
        <Route
        path="verify"
        element={
          <SuspenseComponent>
            <Verify />
          </SuspenseComponent>
        }
      />
      <Route
        path="forgot"
        element={
          <SuspenseComponent>
            <Forgot />
          </SuspenseComponent>
        }
      />
      <Route
        path="404"
        element={<SuspenseComponent>{/* <NotFound /> */}</SuspenseComponent>}
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default Router;
