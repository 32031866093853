import { createReducer } from "@reduxjs/toolkit";
import {
  RECOMMEND_LOADING,
  GET_RECOMMEND,
  RECOMMEND_ERROR,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const recommendReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(RECOMMEND_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(RECOMMEND_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_RECOMMEND, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default recommendReducer;
