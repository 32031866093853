import { createReducer } from "@reduxjs/toolkit";
import {
  BUTTONS_ERROR,
  BUTTONS_LIST,
  BUTTONS_LOADING,
  CREATE_BUTTONS,
  EDIT_BUTTONS,
  DELETE_BUTTONS,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const buttonsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(BUTTONS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(BUTTONS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(BUTTONS_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(CREATE_BUTTONS, (state, { payload }) => {
      Array.isArray(state.data)
        ? state.data.push(payload)
        : (state.data = [payload]);
      state.loading = false;
    })
    .addCase(EDIT_BUTTONS, (state, { payload }) => {
      const index = state.data.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
    })
    .addCase(DELETE_BUTTONS, (state, { payload }) => {
      state.data = state.data.filter((e) => e?.id !== payload?.id);
      state.loading = false;
    });
});

export default buttonsReducer;
