import { createReducer } from "@reduxjs/toolkit";
import {
  DELETE_STATISTICS,
  EDIT_STATISTICS,
  GET_STATISTICS,
  STATISTICS_ERROR,
  STATISTICS_LOADING,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
  chartTypes: [],
};
const statisticsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(STATISTICS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(STATISTICS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_STATISTICS, (state, { payload }) => {
      state.data = payload.module_statistics;
      state.chartTypes = payload.chart_types;
      state.loading = false;
    })
    .addCase(DELETE_STATISTICS, (state, { payload }) => {
      state.data = state.data.filter((e) => e?.id !== payload);
      state.loading = false;
    })
    .addCase(EDIT_STATISTICS, (state, { payload }) => {
      const index = state.data.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
    });
});
export default statisticsReducer;
