import { createReducer } from "@reduxjs/toolkit";
import {
  GET_THEME,
  APP_THEME_ERROR,
  THEME_STEP,
  APP_THEME_LOADING,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  theme: {},
};

const appThemeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(APP_THEME_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(APP_THEME_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_THEME, (state, { payload }) => {
      state.theme = payload;
      state.loading = false;
    })
    .addCase(THEME_STEP, (state, { payload }) => {
      state.theme = {
        ...state?.theme,
        ...payload,
      };
    });
});

export default appThemeReducer;
