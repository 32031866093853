import { createReducer } from "@reduxjs/toolkit";
import {
  CORE_COMPONENT_ERROR,
  CORE_COMPONENT_LOADING,
  CREATE_CORE_COMPONENT,
  DELETE_CORE_COMPONENT,
  EDIT_CORE_COMPONENT,
  LIST_CORE_COMPONENT,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
  count: 0,
  allPages: 0,
  thisPage: 0,
};

const coreComponentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CORE_COMPONENT_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(CORE_COMPONENT_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(LIST_CORE_COMPONENT, (state, { payload }) => {
      state.data =
        payload?.thisPage === 1
          ? payload.data
          : [...state.data, ...payload.data];
      state.allPages = payload.allPages;
      state.thisPage = payload.thisPage;
      state.loading = false;
    })
    .addCase(CREATE_CORE_COMPONENT, (state, { payload }) => {
      Array.isArray(state.data)
        ? state.data.push(payload)
        : (state.data = [payload]);
      state.loading = false;
    })
    .addCase(EDIT_CORE_COMPONENT, (state, { payload }) => {
      const index = state.data.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
    })
    .addCase(DELETE_CORE_COMPONENT, (state, { payload }) => {
      state.data = state.data.filter((e) => e?.id !== payload?.id);
      state.loading = false;
    });
});

export default coreComponentReducer;
