import { BrowserRouter } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import Router from "./Router";
import { useEffect } from "react";
import { LOGIN_SUCCESS } from "./store/actions/types";
import { useDispatch } from "react-redux";

export default function Splash({ colorMode, mode, isRTL }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    token &&
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token,
          user,
        },
      });
  }, []);

  return (
    <BrowserRouter>
      <Router changeMode={colorMode} mode={mode} isRTL={isRTL} />
    </BrowserRouter>
  );
}

export const AppLoader = () => {
  return (
    <Box
      sx={{ height: "100vh" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      justifyItems="center"
    >
      <CircularProgress />
    </Box>
  );
};
