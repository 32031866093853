import { createReducer } from "@reduxjs/toolkit";
import {
  MENU_LOADING,
  MENU_ERROR,
  CREATE_MENU,
  EDIT_MENU,
  DELETE_MENU,
  LIST_MENU
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const MenuReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(MENU_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(MENU_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(LIST_MENU, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(CREATE_MENU, (state, { payload }) => {
      Array.isArray(state.data)
        ? state.data.push(payload)
        : (state.data = [payload]);
      state.loading = false;
    })
    .addCase(EDIT_MENU, (state, { payload }) => {
      const index = state.data.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
    })
    .addCase(DELETE_MENU, (state, { payload }) => {
      state.data = state.data.filter((e) => e?.id !== payload?.id);
      state.loading = false;
    });
});

export default MenuReducer;
